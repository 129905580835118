import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'

// import { Container } from './styles';

export const ModalConfigDashboard: React.FC<{
  onHide: () => void
  widgets: {
    id: string
    name: string
  }[]
  widgetVisible: string[]
  onUpdateItem: (w: string) => void
}> = ({ onHide, widgets, widgetVisible, onUpdateItem }) => {
  return (
    <Modal show centered onHide={onHide}>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">Widgets do Dashboard</h6>
          <button type="button" onClick={onHide}>
            <FaTimes className="m-0" />
          </button>
        </div>
        <hr />
        {widgets.map(w => {
          const c = widgetVisible.find(i => i === w.id)
          return (
            <Form.Group className="p-2 mb-2 radius-4 bg-gray" key={w.id}>
              <Form.Check
                label={w.name}
                checked={!!c}
                onClick={() => onUpdateItem(w.id)}
              />
            </Form.Group>
          )
        })}
        <div className="d-flex justify-content-center mt-3">
          <Button type="button" variant="outline-secondary" onClick={onHide}>
            <FaTimes /> Fechar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
