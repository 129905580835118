import React from 'react'
import { ResponsiveLine } from '@nivo/line'

export const ConfirmationGraphic: React.FC<{ data: any[] }> = ({ data }) => {
  const getColor = bar => {
    return data.find(i => i.id === bar.id)?.color || '#222'
  }
  const serieId = (v: string | number) => {
    const i = data.find(i => i.id === v)
    if (i) {
      return i.label
    }
    return ''
  }
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 15, bottom: 50, left: 35 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false
      }}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      curve="linear"
      colors={getColor}
      useMesh={true}
      enablePoints={false}
      lineWidth={2}
      tooltip={data => {
        return (
          <div
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              background: '#444',
              color: '#fff',
              borderRadius: '4px',
              fontSize: '0.8rem',
              borderLeft: `5px solid ${data.point.serieColor}`
            }}
          >
            {String(data.point.data.x)?.indexOf('/') > -1 ? (
              <strong>{data.point.data.x}</strong>
            ) : (
              serieId(data.point.serieId)
            )}
            : {data.point.data.y}
            {String(data.point.data.x)?.indexOf('/') > -1 ? ' Cadastro(s)' : ''}
          </div>
        )
      }}
    />
  )
}
