import { useFetch } from '@/hooks/api'
import { useAuth } from '@/hooks/useAuth'
import {
  formatDate,
  neutralizeDate,
  normalizeCnpj,
  normalizeCurrency,
  subString
} from '@/utils/normalize'
import { format, subDays } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { useRouter } from 'next/router'
import { BsFileEarmarkCheckFill } from 'react-icons/bs'
import { FaPlusCircle } from 'react-icons/fa'
import { GiReceiveMoney } from 'react-icons/gi'
import { IoDocumentAttachSharp } from 'react-icons/io5'
import {
  MdOutlineWidgets,
  MdQueryBuilder,
  MdVisibility,
  MdWatchLater
} from 'react-icons/md'
import Lottie from 'react-lottie'
import * as animationData from '@/assets/90507-money-saving.json'
import { Badge, Button, Col, ProgressBar } from 'react-bootstrap'
import { HasPermission } from '@/components/ui/HasPermission'
import { AiOutlineDoubleLeft } from 'react-icons/ai'
import { ModalConfigDashboard } from '@/components/ui/ModalConfigDashboard'
import { useEffect, useMemo, useState } from 'react'
import { bfcDigital } from '@/services/bfcDigital'
import { getConfirmationStyle } from '@/components/pages/EConfirmation/utils'
import { ConfirmationGraphic } from '@/components/ui/ConfirmationGraph'
import { IoMdAnalytics } from 'react-icons/io'

interface ChartConfirmation {
  id: TypeEConfirmation
  label: string
  color: string
  data: {
    x: string
    y: number
  }[]
}

export default function Home(): JSX.Element {
  const { user } = useAuth()
  const router = useRouter()

  const [nfeConfirmation, setNfeConfirmation] = useState<
    {
      aConfirmar: number
      confirmadas: number
      dia: number
      naoConfirmadas: number
      naoEncontradas: number
      rejeitadas: number
    }[]
  >([])
  const nfeChart = useMemo(() => {
    const chart: ChartConfirmation[] = [
      {
        id: 'a confirmar',
        label: 'a confirmar',
        color: getConfirmationStyle('a confirmar').backgroundColor,
        data: []
      },
      {
        id: 'confirmado',
        label: 'confirmado',
        color: getConfirmationStyle('confirmado').backgroundColor,
        data: []
      },
      {
        id: 'inexistente',
        label: 'inexistente',
        color: getConfirmationStyle('inexistente').backgroundColor,
        data: []
      },
      {
        id: 'nao confirmado',
        label: 'nao confirmado',
        color: getConfirmationStyle('nao confirmado').backgroundColor,
        data: []
      },
      {
        id: 'nfe nao encontrada',
        label: 'nfe ja confirmada',
        color: getConfirmationStyle('nfe nao encontrada').backgroundColor,
        data: []
      },
      {
        id: 'rejeitado',
        label: 'rejeitado',
        color: getConfirmationStyle('rejeitado').backgroundColor,
        data: []
      },
      {
        id: 'expirado',
        label: 'expirado',
        color: getConfirmationStyle('expirado').backgroundColor,
        data: []
      }
    ]
    nfeConfirmation.forEach(item => {
      chart[0].data.push({
        x: String(item.dia),
        y: item.aConfirmar
      })
      chart[1].data.push({
        x: String(item.dia),
        y: item.confirmadas
      })
      chart[3].data.push({
        x: String(item.dia),
        y: item.naoConfirmadas
      })
      chart[4].data.push({
        x: String(item.dia),
        y: item.naoEncontradas
      })
      chart[5].data.push({
        x: String(item.dia),
        y: item.rejeitadas
      })
    })
    return chart
  }, [nfeConfirmation])

  const [cteConfirmation, setCteConfirmation] = useState<
    {
      aConfirmar: number
      confirmadas: number
      dia: number
      naoConfirmadas: number
      naoEncontradas: number
      rejeitadas: number
    }[]
  >([])
  const cteChart = useMemo(() => {
    const chart: ChartConfirmation[] = [
      {
        id: 'a confirmar',
        label: 'a confirmar',
        color: getConfirmationStyle('a confirmar').backgroundColor,
        data: []
      },
      {
        id: 'confirmado',
        label: 'confirmado',
        color: getConfirmationStyle('confirmado').backgroundColor,
        data: []
      },
      {
        id: 'inexistente',
        label: 'inexistente',
        color: getConfirmationStyle('inexistente').backgroundColor,
        data: []
      },
      {
        id: 'nao confirmado',
        label: 'nao confirmado',
        color: getConfirmationStyle('nao confirmado').backgroundColor,
        data: []
      },
      {
        id: 'rejeitado',
        label: 'rejeitado',
        color: getConfirmationStyle('rejeitado').backgroundColor,
        data: []
      },
      {
        id: 'expirado',
        label: 'expirado',
        color: getConfirmationStyle('expirado').backgroundColor,
        data: []
      }
    ]
    cteConfirmation.forEach(item => {
      chart[0].data.push({
        x: String(item.dia),
        y: item.aConfirmar
      })
      chart[1].data.push({
        x: String(item.dia),
        y: item.confirmadas
      })
      chart[3].data.push({
        x: String(item.dia),
        y: item.naoConfirmadas
      })
      chart[4].data.push({
        x: String(item.dia),
        y: item.rejeitadas
      })
    })
    return chart
  }, [cteConfirmation])

  useEffect(() => {
    bfcDigital
      .get('/requestlist/dashboard/month?system=erp')
      .then(res =>
        setNfeConfirmation(
          res.data.filter(d => d.dia <= neutralizeDate(new Date()).getDate())
        )
      )
      .catch(() => {
        //
      })
  }, [])

  useEffect(() => {
    bfcDigital
      .get('/bordero-report-cte/dashboard/month?system=erp')
      .then(res =>
        setCteConfirmation(
          res.data.filter(d => d.dia <= neutralizeDate(new Date()).getDate())
        )
      )
      .catch(() => {
        //
      })
  }, [])

  const { data: lastAssignors } = useFetch(
    '/assignors?page=1&per_page=15&order_name=createDate&order_direction=desc'
  )
  const { data: operationsPaid } = useFetch(
    '/operations?page=1&per_page=15&status_id=11&order_name=date&order_direction=desc'
  )
  const { data: operationsOpen } = useFetch(
    '/operations?page=1&per_page=15&status_id=2,3,4,7,8,9,10,6&order_name=updatedAt&order_direction=desc'
  )
  const { data: titlesPaid } = useFetch(
    '/titles?page=1&per_page=15&situation=paid&order_name=paidDate&order_direction=desc'
  )
  const { data: assignorsReport } = useFetch('/dashboard/assignors')

  const widgets = [
    {
      id: 'assignors-report',
      name: 'Estatística de Cadastro',
      cols: 8,
      element: (
        <div
          className="bg-white p-3 shadow-sm radius-4"
          style={{ height: '30vh' }}
        >
          <div className="d-flex align-items-center">
            <strong>
              <IoMdAnalytics /> Estatística de Cadastro
            </strong>
            <i className="ml-3">
              {' '}
              {format(subDays(neutralizeDate(new Date()), 29), "dd 'de' MMMM", {
                locale: ptBR
              })}{' '}
              à{' '}
              {format(neutralizeDate(new Date()), "dd 'de' MMMM", {
                locale: ptBR
              })}
            </i>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 d-flex flex-column">
              <small>Total</small>
              <strong>{assignorsReport?.total}</strong>
            </div>
            <div className="col-md-3 d-flex flex-column">
              <small>Aprovados</small>
              <strong>{assignorsReport?.approved}</strong>
            </div>
            <div className="col-md-3 d-flex flex-column">
              <small>Em análise</small>
              <strong>{assignorsReport?.finished}</strong>
            </div>
            <div className="col-md-3 d-flex flex-column">
              <small>Incompletos</small>
              <strong>{assignorsReport?.incompletes}</strong>
            </div>
            <div className="col-md-12 mt-2">
              <ProgressBar
                style={{ borderRadius: '2rem', height: '0.6rem' }}
                className="mb-3"
              >
                <ProgressBar
                  now={
                    assignorsReport
                      ? (assignorsReport?.approved * 100) /
                        assignorsReport?.total
                      : 0
                  }
                  style={{ backgroundColor: '#1ad194' }}
                />
                <ProgressBar
                  now={
                    assignorsReport
                      ? (assignorsReport?.finished * 100) /
                        assignorsReport?.total
                      : 0
                  }
                  animated
                  style={{ backgroundColor: '#1ba8e0' }}
                />
                <ProgressBar
                  now={
                    assignorsReport
                      ? (assignorsReport?.incompletes * 100) /
                        assignorsReport?.total
                      : 0
                  }
                  animated
                  style={{ backgroundColor: '#c9c9c9' }}
                />
              </ProgressBar>
            </div>
            <div className="col-md-12" style={{ height: '19vh' }}>
              <ConfirmationGraphic
                data={[
                  {
                    id: 'cadastro',
                    label: 'Cadastro',
                    color: '#1ba8e0',
                    data:
                      assignorsReport?.last30DaysRegisters?.days
                        ?.map(d => ({
                          x: `${d.date.split('/')[0]}/${d.date.split('/')[1]}`,
                          y: d.total
                        }))
                        .slice(0)
                        .reverse() || []
                  }
                ]}
              />
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'last-assignors',
      name: 'Novos Cadastros',
      cols: 4,
      element: (
        <div
          className="bg-blues shadow-sm radius-4"
          style={{ height: '30vh', overflow: 'hidden' }}
        >
          <strong className="text-primary d-block pl-3 pr-3 pt-3">
            <IoDocumentAttachSharp className="mb-1" /> Novos Cadastros
          </strong>
          <div
            style={{ height: '100%', overflow: 'auto' }}
            className="pl-3 pr-3"
          >
            <table className="w-100">
              <tbody>
                {lastAssignors?.data?.map((assignor: Assignor) => (
                  <tr className="bg-none" key={assignor.id}>
                    <td className="small pl-0 pt-1 pb-0">
                      {assignor?.code} - {subString(assignor.name, 26)}
                    </td>
                    <td className="small text-center pl-0 pb-0 pt-1">
                      {assignor.is_approved && (
                        <Badge variant="success">Aprovado</Badge>
                      )}
                      {assignor.is_finished && !assignor.is_approved && (
                        <Badge variant="primary">Em análise</Badge>
                      )}
                      {!assignor.is_finished && !assignor.is_approved && (
                        <Badge variant="secondary">Incompleto</Badge>
                      )}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {normalizeCnpj(assignor.cnpj)}
                    </td>
                    <td className="small pr-0 pl-2 pt-1 pb-0">
                      <button
                        type="button"
                        onClick={() =>
                          router.push(`/assignors/create?id=${assignor.id}`)
                        }
                      >
                        <MdVisibility className="m-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    },
    {
      id: 'operations-paid',
      name: 'Operações Pagas',
      cols: 3,
      element: (
        <div
          className="bg-light-green shadow-sm radius-4"
          style={{ height: '30vh', overflow: 'hidden' }}
        >
          <strong className="text-success d-block pl-3 pr-3 pt-3">
            <GiReceiveMoney className="mb-1" /> Operações Pagas
          </strong>
          <div style={{ height: '100%', overflow: 'auto' }} className="pl-3">
            <table className="w-100">
              <tbody>
                {operationsPaid?.data?.map((operation: Operation) => (
                  <tr className="bg-none" key={operation.id}>
                    <td className="small pl-0 pt-1 pb-0">{operation.code}</td>
                    <td className="small pr-0 pt-1 pb-0">
                      {normalizeCurrency(operation.net_value, true)}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {formatDate(operation.date)}
                    </td>
                    <td className="small pr-0 pl-2 pt-1 pb-0">
                      <button
                        type="button"
                        onClick={() =>
                          router.push(`/operations/register?id=${operation.id}`)
                        }
                      >
                        <MdVisibility className="m-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    },
    {
      id: 'operations-open',
      name: 'Operações Em Aberto',
      cols: 5,
      element: (
        <div
          className="bg-white shadow-sm radius-4"
          style={{ height: '30vh', overflow: 'hidden' }}
        >
          <strong className="text-main d-block pl-3 pr-3 pt-3">
            <MdWatchLater className="mb-1" /> Operações em aberto
          </strong>
          <div
            style={{ overflow: 'auto', height: '100%' }}
            className="pl-3 pr-3"
          >
            <table className="w-100">
              <tbody>
                {operationsOpen?.data?.map((operation: Operation) => (
                  <tr className="bg-none" key={operation.id}>
                    <td className="small pl-0 pt-1 pb-0">{operation.code}</td>
                    <td className="small pl-0 pt-1 pb-0">
                      {subString(operation?.assignor?.name, 30)}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {normalizeCurrency(operation?.net_value || '000', true)}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {operation?.status?.name}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {formatDate(operation?.updated_at, true)}
                    </td>
                    <td className="small pr-0 pl-2 pt-1 pb-0">
                      <button
                        type="button"
                        onClick={() =>
                          router.push(`/operations/register?id=${operation.id}`)
                        }
                      >
                        <MdVisibility className="m-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    },
    {
      id: 'titles-paid',
      name: 'Títulos Baixados',
      cols: 4,
      element: (
        <div
          className="bg-white shadow-sm radius-4"
          style={{ height: '30vh', overflow: 'hidden' }}
        >
          <strong className="text-muted d-block pl-3 pr-3 pt-3">
            <BsFileEarmarkCheckFill className="mb-1" /> Títulos Baixados
          </strong>
          <div
            style={{ overflow: 'auto', height: '100%' }}
            className="pl-3 pr-3"
          >
            <table className="w-100">
              <tbody>
                {titlesPaid?.data?.map((title: Title) => (
                  <tr className="bg-none" key={title.id}>
                    <td className="small pl-0 pt-1 pb-0">
                      {title.document_number}
                    </td>
                    <td className="small pl-0 pt-1 pb-0">
                      {subString(title.payer?.name, 23)}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {normalizeCurrency(title?.value, true)}
                    </td>
                    <td className="small pr-0 pt-1 pb-0 text-right">
                      {formatDate(title?.paid_date)}
                    </td>
                    <td className="small pr-0 pl-2 pt-1 pb-0">
                      <button
                        type="button"
                        onClick={() => router.push(`/titles?id=${title.id}`)}
                      >
                        <MdVisibility className="m-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    },
    {
      id: 'nfe-confirmation',
      name: 'NFe-s Analisadas',
      cols: 6,
      element: (
        <div
          className="bg-white shadow-sm radius-4 p-3"
          style={{ height: '30vh' }}
        >
          <div className="d-flex align-items-center">
            <strong>NFe-s Analisadas</strong>
            <i className="ml-2">
              em{' '}
              {format(neutralizeDate(new Date()), 'MMMM', {
                locale: ptBR
              })}
            </i>
          </div>
          <ConfirmationGraphic data={nfeChart} />
        </div>
      )
    },
    {
      id: 'cte-confirmation',
      name: 'CTe-s Analisadas',
      cols: 6,
      element: (
        <div
          className="bg-white shadow-sm radius-4 p-3"
          style={{ height: '30vh' }}
        >
          <div className="d-flex align-items-center">
            <strong>CTe-s Analisadas</strong>{' '}
            <i className="ml-2">
              em{' '}
              {format(neutralizeDate(new Date()), 'MMMM', {
                locale: ptBR
              })}
            </i>
          </div>

          <ConfirmationGraphic data={cteChart} />
        </div>
      )
    }
  ]
  const [widgetVisible, setWidgetVisible] = useState<string[]>(() => {
    const d = localStorage.getItem('widgets__dash')
    if (d) {
      return JSON.parse(d)
    }
    return []
  })

  useEffect(() => {
    localStorage.setItem('widgets__dash', JSON.stringify(widgetVisible))
  }, [widgetVisible])

  const [config, setConfig] = useState(false)

  return (
    <main>
      {config && (
        <ModalConfigDashboard
          onHide={() => {
            setConfig(false)
          }}
          onUpdateItem={item => {
            if (widgetVisible.find(w => w === item)) {
              setWidgetVisible(widgetVisible.filter(w => w !== item))
            } else {
              setWidgetVisible([...widgetVisible, item])
            }
          }}
          widgetVisible={widgetVisible}
          widgets={widgets.map(w => ({
            id: w.id,
            name: w.name
          }))}
        />
      )}

      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              width: '90px',
              height: '60px',
              overflow: 'hidden'
            }}
          >
            <Lottie
              options={{
                animationData,
                loop: true,
                autoplay: true
              }}
              height={90}
              width={90}
            />
          </div>
          <div>
            <h6 className="mb-0">
              Olá, {user?.name?.split(' ')[0]}. Bem Vindo a BFC Digital.
            </h6>
            <small>
              <MdQueryBuilder /> Última atualização às{' '}
              {format(neutralizeDate(new Date()), "HH:mm 'de' dd/MM/yyyy")}
            </small>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div className="d-flex">
              <HasPermission permission="Adicionar Operações">
                <button
                  type="button"
                  onClick={() => router.push('/operations/register')}
                  className="p-0"
                >
                  <Badge variant="primary">
                    <FaPlusCircle className="mb-0" />{' '}
                    <strong className="mb-0">Nova Operação</strong>
                  </Badge>
                </button>
              </HasPermission>
              <HasPermission permission="Adicionar Cedente">
                <button
                  type="button"
                  onClick={() => router.push('/assignors/create')}
                  className="ml-2 p-0"
                >
                  <Badge variant="primary">
                    <FaPlusCircle className="mb-0" />{' '}
                    <strong className="mb-0">Novo Cedente</strong>
                  </Badge>
                </button>
              </HasPermission>
              <HasPermission permission="Adicionar Sacados">
                <button
                  type="button"
                  onClick={() => router.push('/payers/create')}
                  className="ml-2 p-0"
                >
                  <Badge variant="primary">
                    <FaPlusCircle className="mb-0" />{' '}
                    <strong className="mb-0">Novo Sacado</strong>
                  </Badge>
                </button>
              </HasPermission>
            </div>
            <h5 className="small text-muted text-right mt-2">
              <AiOutlineDoubleLeft /> ATALHOS
            </h5>
          </div>
          <Button
            className="ml-2"
            type="button"
            style={{ width: '3rem', height: '3rem' }}
            onClick={() => setConfig(true)}
          >
            <MdOutlineWidgets className="m-0" size="1.4rem" />
          </Button>
        </div>
      </div>

      <div className="row mt-3">
        {widgetVisible.length === 0 && (
          <div className="col-md-12 mt-3">
            <div
              className="bg-blues d-flex justify-content-center align-items-center flex-column p-4 radius-4 text-muted text-center"
              style={{ height: '70vh' }}
            >
              <MdOutlineWidgets size="3rem" />
              <h5>Você não tem nenhum widget em exibição</h5>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => setConfig(true)}
              >
                Clique aqui para configurar
              </button>
            </div>
          </div>
        )}
        {widgetVisible.map(widget => {
          const item = widgets.find(w => w.id === widget)
          if (item) {
            return (
              <Col className="mb-4" lg={item.cols}>
                {item.element}
              </Col>
            )
          }
          return <></>
        })}
      </div>
    </main>
  )
}
