import { ProgressBar } from 'react-bootstrap'

export const typesStatus = [
  {
    title: 'Em análise',
    type: ''
  },
  {
    title: 'Confirmado',
    type: 'confirmado'
  },
  {
    title: 'A confirmar',
    type: 'a confirmar'
  },
  {
    title: 'Rejeitado',
    type: 'rejeitado'
  },
  {
    title: 'Expirado',
    type: 'expirado'
  },
  {
    title: 'Não confirmado',
    type: 'nao confirmado'
  },
  {
    title: 'Já confirmada',
    type: 'nfe nao encontrada'
  },
  {
    title: 'Inexistente',
    type: 'inexistente'
  }
]

export const getConfirmationStyle = (
  type: TypeEConfirmation
): { backgroundColor: string; color: string } => {
  const style = {
    backgroundColor: '#eee',
    color: '#fff'
  }
  if (type === 'confirmado') {
    style.backgroundColor = '#1dc9b7'
  } else if (type === 'a confirmar') {
    style.backgroundColor = '#366cf3'
  } else if (type === 'rejeitado') {
    style.backgroundColor = '#fd397a'
  } else if (type === 'expirado') {
    style.backgroundColor = '#fd3939'
  } else if (type === 'nao confirmado') {
    style.backgroundColor = '#282a3c'
  } else if (type === 'nfe nao encontrada') {
    style.backgroundColor = 'rgb(255, 125, 0)'
  } else if (type === 'inexistente') {
    style.backgroundColor = 'rgb(119, 0, 255)'
  } else {
    style.color = '#444'
  }
  return style
}
export const getProgress = (
  type: TypeEConfirmation,
  percentage: number,
  key: number,
  label = true
): JSX.Element => {
  const style = getConfirmationStyle(type)
  return (
    <ProgressBar
      now={percentage}
      style={{ backgroundColor: style.backgroundColor, color: style.color }}
      label={label && percentage > 7 ? `${percentage}%` : ''}
      key={key}
    />
  )
}

export const getPercentages = (
  nfes: {
    chNfe: string
    cnpj: string
    events: string
    origin: string
    status: TypeEConfirmation
    ufSacado: string
    sacado: string
    sacadoDocument: string
    processDate: string
    updatedAt: string
  }[]
): { status: TypeEConfirmation; percentage: number }[] => {
  const obj: any = {}
  nfes.forEach(nfe => {
    if (obj[nfe.status]) {
      obj[nfe.status] += 1
    } else {
      obj[nfe.status] = 1
    }
  })
  const arr: { status: TypeEConfirmation; percentage: number }[] = []
  Object.keys(obj).forEach((key: TypeEConfirmation) => {
    arr.push({
      status: key,
      percentage: Math.round((obj[key] * 100) / nfes.length)
    })
  })
  return arr
}

export function TypeStatusLegend(): JSX.Element {
  return (
    <div className="d-flex flex-wrap align-items-center">
      {typesStatus.map(type => (
        <div key={type.type} className="d-flex align-items-center mr-3">
          <div
            style={{
              ...getConfirmationStyle(type.type as any),
              width: '1rem',
              height: '1rem',
              marginRight: '0.3rem',
              borderRadius: '0.2rem'
            }}
          ></div>{' '}
          {type.title}
        </div>
      ))}
    </div>
  )
}
